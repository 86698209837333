<template>
  <transition name="lsg-modal">
    <div class="lsg-modal-mask">
      <div class="lsg-modal-wrapper">
        <div :class="{ 'lsg-modal-container': true, 'lsg-modal-attention': attention }">
          <div class="lsg-modal-ico">
            <slot name="ico">
            </slot>
          </div>
          <div class="lsg-modal-header">
            <slot name="header">
            </slot>
          </div>
          <div class="lsg-modal-body">
            <slot name="body">
            </slot>
          </div>
          <div class="lsg-modal-footer">
            <slot name="footer">
              <button class="lsg-btn lsg-btn-default lsg-modal-default-button" @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LsgModal',
  props: {
    attention: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus">
.lsg-modal
  &-mask
    position fixed
    z-index 9999
    top 0
    left 0
    width 100%
    height 100%
    padding-left 280px
    background-color alpha($dark, 0.5)
    transition opacity .3s ease

  &-wrapper
    display flex
    align-items center
    justify-content center
    width 100%
    height 100vh

  &-container
    width 100%
    max-width 550px
    padding 30px
    background-color $white
    border-radius 10px
    box-shadow alpha($dark, 20%) 0 30px 60px
    transition all .3s ease

  &-ico
    text-align center

  &-header
    h3
      margin-top 0

  &-body
    margin 0

    textarea
      border-radius 5px

    .calc__item
      margin 0
      padding 0

    .calc__content
      padding 0

      .group_buttons__item
        svg
          path
            stroke $dark
            transition stroke 0.3s

        &:hover
          svg
            path
              stroke $white

      > .button
        margin-left 0 !important
        margin-top 20px

        &[type="submit"]
          margin-right 15px

  &-footer
    display flex
    justify-content flex-end
    align-items center

    button
      margin-left 15px

  &-attention
    text-align center

    h2
      margin 20px 0

    .lsg-modal-footer
      margin-top 30px
      justify-content center

.content__wide
  .lsg-modal
    &-mask
      padding-left 80px

</style>
