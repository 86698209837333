<template>
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 47.6663C37.9662 47.6663 47.6667 37.9658 47.6667 25.9997C47.6667 14.0335 37.9662 4.33301 26 4.33301C14.0339 4.33301 4.33337 14.0335 4.33337 25.9997C4.33337 37.9658 14.0339 47.6663 26 47.6663Z" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M26 34.6667V26" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M26 17.333H26.0213" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
</template>

<script>
export default {
  name: 'AttentionIco'
}
</script>
